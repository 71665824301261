// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vRyxmlJuIvG1R87x7zAm {
  font-family: "Sarabun", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.U8HfV5dMvGtreMdYSLwQ {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 450px) {
  .U8HfV5dMvGtreMdYSLwQ {
    width: 1000%;
    margin: 0 auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/utils/ChangePassword/index.module.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;IACZ,cAAc;EAChB;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap\");\n\n.text {\n  font-family: \"Sarabun\", sans-serif;\n  font-weight: 700;\n  font-style: normal;\n}\n\n.boxEmail {\n  width: 50%;\n  margin: 0 auto;\n}\n\n@media (max-width: 450px) {\n  .boxEmail {\n    width: 1000%;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `vRyxmlJuIvG1R87x7zAm`,
	"boxEmail": `U8HfV5dMvGtreMdYSLwQ`
};
export default ___CSS_LOADER_EXPORT___;
