import { useTranslation } from "react-i18next";
import { Tooltip, TableCell, Box } from "@mui/material";

import { BasicTable } from "components";
import HeightIcon from "@mui/icons-material/Height";
import { useState } from "react";

export const TableReports = ({
  followUpHeader,
  finalHeader,
  rows,
  setRows,
  dontShow,
  handleClick
}) => {
  const { t } = useTranslation(["shiftAttendance"]);

  const [sorting, setSorting] = useState("ASC");
  const [sortType, setSortType] = useState("EMPLOYEE");

  const handleChangeSort = (event) => {
    const direction =
      event !== sortType ? "ASC" : sorting === "ASC" ? "DESC" : "ASC";
    setSorting(direction);
    setSortType(event);
    setRows(sortData({ filterData: rows, orderBy: event, direction }));
  };

  const sortData = ({ filterData, orderBy = "FOLIO", direction = "ASC" }) => {
    if (orderBy === "FOLIO") {
      filterData = filterData.sort((a, b) =>
        direction === "DESC"
          ? a.folio.localeCompare(b.folio)
          : b.folio.localeCompare(a.folio),
      );
    }

    return filterData;
  };

  const head = [
    t("shiftAttendance:Folio"),
    t("shiftAttendance:RequestDate"),
    followUpHeader,
    t("shiftAttendance:InitialDate"),
    t("shiftAttendance:EndDate"),
    t("shiftAttendance:VacationDays"),
    finalHeader,
  ];

  return (
    <>
      <BasicTable
        rows={rows}
        handleClick={handleClick}
        customStyle={"AttendanceReports"}
        PerPage={10}
        color={false}
        dense={true}
        hover={(!finalHeader) ? false : true}
        dontShow={dontShow}
      >
        <TableCell>
          <Tooltip title={t("general:Ordenar")}>
            <Box
              sx={{ display: "flex", cursor: "pointer" }}
              onClick={() => {
                handleChangeSort("FOLIO");
              }}
            >
              {head[0]}
              <HeightIcon sx={{ opacity: "0.9" }} />
            </Box>
          </Tooltip>
        </TableCell>
        {head.slice(1).map(
          (header, index) =>
            header && (
              <TableCell  key={index}>
                {header}
              </TableCell>
            ),
        )}
      </BasicTable>
    </>
  );
};
