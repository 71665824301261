import { useEffect, useState } from "react";
import {
  Avatar,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectingEmployeeList, requestCheckFiltro, getCheck } from "api";
import dayjs from "dayjs";
import { TrakingModal } from "./TrakingModal";
import { ExportTrackingData } from "./ExportTrackingData";
import { FromFilter } from "./FormFilter";
import { DataGrid } from "@mui/x-data-grid";

export const Tracking = () => {
  const { t } = useTranslation();

  const [filtersOptions, setFiltersOptions] = useState({});
  const [employees, setEmployees] = useState({});
  const [finish, setFinish] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState([
    { field: "registerAt", sort: "asc" },
  ]);
  const [dataGrid, setDataGrid] = useState({
    rowCount: 0,
    rows: [],
    isLoading: false,
  });
  const [filters, setFilters] = useState({});
  const [allData, setAllData] = useState({});
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [disabledFilters, setDisabledFilters] = useState(false);

  const columns = [
    { field: "_id" },
    {
      field: "avatar",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <Avatar
            src={params.row.avatar}
            sx={{
              width: 24,
              height: 24,
            }}
          />
        );
      },
      width: 28,
    },
    {
      field: "fullName",
      headerName: t("shiftAttendance:FullName"),
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "department",
      headerName: t("shiftAttendance:Department"),
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "registerAt",
      headerName: t("shiftAttendance:RegisterAt"),
      flex: 1,
      minWidth: 150,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "type",
      headerName: t("shiftAttendance:Type"),
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "schedule",
      headerName: t("shiftAttendance:Schedule"),
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "timeClock",
      headerName: t("shiftAttendance:TimeClock"),
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      const dataFiltersOptions = await requestCheckFiltro({ t });
      const { data: employeesData } = await selectingEmployeeList();
      setEmployees(
        employeesData
          .map((e) => {
            e.department = e?.department ? e.department : "WithoutDepartment";
            return e;
          })
          .reduce((res, i) => ((res[i._id] = i), res), {}),
      );

      const datesWithData = dataFiltersOptions.datesWithData.length
        ? dataFiltersOptions.datesWithData
        : [dayjs().format("YYYY-MM-DD")];
      const filterInit = {
        registerAtFrom: datesWithData.length ? datesWithData[0] : null,
        registerAtTo: datesWithData.length
          ? datesWithData[datesWithData.length - 1]
          : null,
      };
      dataFiltersOptions.employees = dataFiltersOptions.employees.map((e) => {
        e.department = e?.department ? e.department : "WithoutDepartment";
        return e;
      });

      setFilters(filterInit);
      setFiltersOptions(dataFiltersOptions);

      setFinish(true);
    };

    getData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setDataGrid((old) => ({ ...old, isLoading: true }));
      setDisabledFilters(true);
      // console.log("sortModel => ", sortModel);

      const sendFilters = {
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        order_by: sortModel.map((s) =>
          s.sort === "asc" ? s.field : `-${s.field}`,
        ),
        ...filters,
      };
      const json = await getCheck({
        params: sendFilters,
        t,
      });
      const rows = json.data.map(
        ({ _id, employee, type, registerAt, schedule, timeClock }) => {
          const row = {
            _id,
            registerAt,
            avatar: employees[employee]?.thumbnail,
            fullName: employees[employee]?.fullName,
            department: employees[employee]?.department,
            schedule: schedule.name,
            timeClock: timeClock.name,
            type: t(`shiftAttendance:${type}`),
          };

          return row;
        },
      );

      setDataGrid((old) => ({
        ...old,
        isLoading: false,
        rows: rows,
        rowCount: json.total,
      }));

      setAllData(json.data.reduce((res, o) => ((res[o._id] = o), res), {}));
      setDisabledFilters(false);
    };

    if (finish) {
      fetchData();
    }
  }, [
    finish,
    paginationModel.page,
    paginationModel.pageSize,
    filters,
    sortModel,
  ]);

  return finish ? (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">{t("shiftAttendance:Tracking")}</Typography>
      <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
        <FromFilter
          filtersOptions={filtersOptions}
          filters={filters}
          setFilters={setFilters}
          disabled={disabledFilters}
          exportButton={
            dataGrid.rows.length ? (
              <ExportTrackingData
                filters={filters}
                employees={employees}
                disabled={disabledFilters}
                setDisabled={setDisabledFilters}
              />
            ) : (
              ""
            )
          }
        />
      </Paper>
      <DataGrid
        sx={{
          boxShadow: 2,
          border: 0,
          height: "75vh",
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _id: false,
            },
          },
        }}
        rows={dataGrid.rows}
        loading={dataGrid.isLoading}
        rowCount={dataGrid.rowCount}
        columns={columns}
        pageSizeOptions={[10, 25, 50]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        getRowId={(row) => row._id}
        disableColumnMenu
        onRowClick={(params) => {
          setModalData(allData[params.id]);
          setOpen(true);
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
      {modalData && (
        <TrakingModal
          open={open}
          setOpen={setOpen}
          check={modalData}
          employees={employees}
        />
      )}
    </Paper>
  ) : (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
};
