export const titleResumenStyle = {
  font: { sz: 16, bold: true, color: { rgb: "FFFFFF" } },
    fill: { fgColor: { rgb: "0B5394" } },
    alignment: { horizontal: "center", vertical: "center" }
};

export const headerStyleResumen = {
  font: { color: { rgb: "FFFFFF" } },
  fill: {fgColor: { rgb: "3D85C6"}},
  border: {
    bottom: { style: "medium", color: { hex: "434343" } },
  },
  alignment: { horizontal: "center", vertical: "center", wrapText: true },
};
export const headerStyleAutorizadas = {
  fill: { fgColor: { rgb: "4a86e8" } },
  font: { bold: true, color: { rgb: "FFFFFF" } },
  alignment: { horizontal: "center", vertical: "center", wrapText: true },
};
export const headerStyleRechazadas = {
  fill: { fgColor: { rgb: "ea4335" } },
  font: { bold: true, color: { rgb: "FFFFFF" } },
  alignment: { horizontal: "center", vertical: "center", wrapText: true },
};
export const headerStyleCanceladas = {
  fill: { fgColor: { rgb: "ff9900" } },
  font: { bold: true, color: { rgb: "FFFFFF" } },
  alignment: { horizontal: "center", vertical: "center", wrapText: true },
};

export const rowEmployeeInfoStyle = {
  font: { bold: true  },
};

export const authorizedEntrieStyle = {
  fill: { fgColor: { rgb: "CFE2F3" }},
}
export const rejectedEntrieStyle = {
  fill: { fgColor: { rgb: "F4CCCC" }},
}
export const cancelledEntrieStyle = {
  fill: { fgColor: { rgb: "FFF2CC" }},
}
