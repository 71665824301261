import { getSeniorityString } from "./ExcelFunctions";

export function generateResumenScheme(t) {
  return [
    [
      {
        v: `${t("shiftAttendance:GeneralVacationReport")}    |    ${t("shiftAttendance:ReportingDate")}: ${new Date().toISOString().split("T")[0]}`,
        s: { alignment: { horizontal: "center", vertical: "center" } },
      },
    ],
    [],
    [],
    [],
    [
      t("shiftAttendance:Empleado"),
      t("shiftAttendance:CTemployeeNumber"),
      t("shiftAttendance:CTposition"),
      t("shiftAttendance:CTdepartment"),
      t("shiftAttendance:EntranceDate"),
      t("shiftAttendance:TotalVacationDays").replace(/ /g, "\n"),
      t("shiftAttendance:GrantedDays"),
      t("shiftAttendance:AvalibleDays"),
    ],
  ];
}

export function generateEmployeeScheme(employeeData, t) {
  function getFormattedTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes} hrs`;
  }

  return [
    [
      null,
      `${employeeData.employee}    |    ${t("shiftAttendance:ReportingDate")}: ${new Date().toISOString().split("T")[0]}`,
    ],
    [null],
    [
      null,
      t("shiftAttendance:EntranceDate"),
      { v: employeeData.dateJoiningCompany, t: "d", z: "yyyy-mm-dd" },
      null,
      {
        v: t("shiftAttendance:DateNow"),
        s: { alignment: { horizontal: "right", vertical: "right" } },
      },
      { v: new Date().toISOString().split("T")[0], t: "d", z: "yyyy-mm-dd" },
    ],
    [
      null,
      t("shiftAttendance:CTemployeeNumber"),
      employeeData.number,
      null,
      {
        v: t("shiftAttendance:Hour"),
        s: { alignment: { horizontal: "right", vertical: "right" } },
      },
      {
        v: getFormattedTime(),
        s: { alignment: { horizontal: "right", vertical: "right" } },
      },
    ],
    [
      null,
      t("shiftAttendance:CTposition"),
      employeeData.position,
      null,
      {
        v: t("shiftAttendance:Seniority"),
        s: { alignment: { horizontal: "right", vertical: "right" } },
      },
      {
        v: `${getSeniorityString(employeeData.dateJoiningCompany, t)}`,
        s: { alignment: { horizontal: "right", vertical: "right" } },
      },
    ],
    [null, t("shiftAttendance:CTdepartment"), employeeData.department],
    [null, t("shiftAttendance:CTemail"), employeeData.email],
    [null, t("shiftAttendance:TotalVacationDays"), employeeData.balance],
    [null, t("shiftAttendance:GrantedDays"), employeeData.daysGranted],
    [null, t("shiftAttendance:AvalibleDays"), employeeData.availableDays],
    [null],
    [null],
    [
      null,
      {
        v: t("shiftAttendance:Authorised").toUpperCase(),
        s: { font: { bold: true, color: { rgb: "4285f4" } } },
      },
    ],
    [
      null,
      t("shiftAttendance:Folio"),
      t("shiftAttendance:RequestDate"),
      t("shiftAttendance:ApprovalDate"),
      t("organization:approvers") + " - " + t("shiftAttendance:ApprovalDate"),
      t("shiftAttendance:InitialDate"),
      t("shiftAttendance:EndDate"),
      t("shiftAttendance:VacationDays"),
      t("shiftAttendance:Balance"),
    ],
    [null],
    [null],
    [
      null,
      {
        v: t("shiftAttendance:Rejected").toUpperCase(),
        s: { font: { bold: true, color: { rgb: "ea4335" } } },
      },
    ],
    [
      null,
      t("shiftAttendance:Folio"),
      t("shiftAttendance:RequestDate"),
      t("shiftAttendance:RejectionDate"),
      t("shiftAttendance:Rejected") +
        " - " +
        t("shiftAttendance:RejectionDate"),
      t("shiftAttendance:InitialDate"),
      t("shiftAttendance:EndDate"),
      t("shiftAttendance:VacationDays"),
    ],
    [null],
    [null],
    [
      null,
      {
        v: t("shiftAttendance:Cancelled").toUpperCase(),
        s: { font: { bold: true, color: { rgb: "ff9900" } } },
      },
    ],
    [
      null,
      t("shiftAttendance:Folio"),
      t("shiftAttendance:RequestDate"),
      t("shiftAttendance:RejectionDate"),
      t("shiftAttendance:AuthorizedBy") + "/" + t("shiftAttendance:RejectedBy"),
      t("shiftAttendance:InitialDate"),
      t("shiftAttendance:EndDate"),
      t("shiftAttendance:VacationDays"),
    ],
  ];
}
export const indexAuthorizedHeader = 13;
