import { Grid, Stack } from "@mui/material";
import { UIButton, UIDatePickerFast, UISelectMultipleFast } from "components";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const FromFilter = ({
  filtersOptions,
  filters,
  setFilters,
  exportButton,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [showAdditionalFilters, setShowAdvancedFilters] = useState(false);
  const icon =
    filters?.department?.length ||
    filters?.employee?.length ||
    filters?.type?.length ? (
      <FilterAltIcon />
    ) : null;

  const enableDates = filtersOptions.datesWithData;

  const employees = filters?.department?.length ? [] : filtersOptions.employees;

  const [employeesOptions, setEmployeesOptions] = useState(
    _.map(employees, (e) => ({
      value: e._id,
      label: e.fullName,
    })),
  );

  const departmentsOptions = _.map(filtersOptions.departments, (d) => ({
    value: d ? d : "WithoutDepartment",
    label: d ? d : t("shiftAttendance:WithoutDepartment"),
  }));

  const timeClocksOptions = _.map(filtersOptions.timeClocks, (tc) => ({
    value: tc._id,
    label: tc.name,
  }));

  const typesOptions = _.map(filtersOptions.types, (type) => ({
    value: type,
    label: t(`shiftAttendance:${type}`),
  }));

  function disableDates(date) {
    return !enableDates.includes(date.format("YYYY-MM-DD"));
  }

  const onSubmit = (values) => {
    setFilters({ ...filters, ...values });
  };

  return (
    <Formik initialValues={filters} onSubmit={onSubmit}>
      {(formik) => (
        <Form autoComplete="false">
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <UIDatePickerFast
                name="registerAtFrom"
                label={t("shiftAttendance:RegisterAtFrom")}
                shouldDisableDate={disableDates}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <UIDatePickerFast
                name="registerAtTo"
                label={t("shiftAttendance:RegisterAtTo")}
                shouldDisableDate={disableDates}
                disabled={disabled}
              />
            </Grid>
            {showAdditionalFilters && (
              <>
                <Grid item xs={12} md={12}>
                  <UISelectMultipleFast
                    name="department"
                    label={t("shiftAttendance:Departments")}
                    options={departmentsOptions}
                    disabled={disabled}
                    onChange={(e, newOptions) => {
                      const departmentValues = newOptions.map((o) => o.value);

                      if (departmentValues.length) {
                        const newEmployeesOptions = _.map(
                          _.filter(filtersOptions.employees, (o) =>
                            departmentValues.includes(o.department),
                          ),
                          ({ _id, fullName }) => ({
                            value: _id,
                            label: fullName,
                          }),
                        );

                        setEmployeesOptions(newEmployeesOptions);

                        if (formik?.values?.employee) {
                          const newEmployees = _.map(
                            _.filter(newEmployeesOptions, (e) =>
                              formik.values.employee.includes(e.value),
                            ),
                            ({ value }) => value,
                          );

                          formik.setFieldValue("employee", newEmployees);
                        }
                      } else {
                        setEmployeesOptions(
                          _.map(
                            filtersOptions.employees,
                            ({ _id, fullName }) => ({
                              value: _id,
                              label: fullName,
                            }),
                          ),
                        );
                      }

                      formik.setFieldValue("department", departmentValues);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <UISelectMultipleFast
                    name="employee"
                    label={t("shiftAttendance:Employees")}
                    options={employeesOptions}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ display: "none" }}>
                  <UISelectMultipleFast
                    name="timeClock"
                    label={t("shiftAttendance:TimeClocks")}
                    options={timeClocksOptions}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <UISelectMultipleFast
                    name="type"
                    label={t("shiftAttendance:TypesRegister")}
                    options={typesOptions}
                    disabled={disabled}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Stack
            mt={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <UIButton
              label={t("shiftAttendance:AdditionalFilters")}
              onClick={() => setShowAdvancedFilters(!showAdditionalFilters)}
              fullWidth={false}
              disabled={disabled}
              startIcon={icon}
            />
            <UIButton
              type="submit"
              label={t("shiftAttendance:Filter")}
              fullWidth={false}
              disabled={disabled}
            />
            {exportButton}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
