import { Grid } from "@mui/material";
import SelectingTable from "components/Table/SelectingTable";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UIButton } from "components";
import {
  setWorkSchedule,
  setAllWorkSchedules,
} from "@redux/slices/work-schedules";
import { requestSchedule } from "api";

export const WorkSchedules = () => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [reponseData, setResponseData] = useState([]);
  const [selected, setSelected] = useState([]);

  const headCells = [
    {
      id: "name",
      label: t("shiftAttendance:name"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "description",
      label: t("shiftAttendance:Description"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "type",
      label: t("shiftAttendance:Type"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "isActive",
      label: t("shiftAttendance:Active"),
      numeric: false,
      disablePadding: true,
    },
  ];

  useEffect(() => {
    const list = async () => {
      try {
        const data = await requestSchedule({ method: "GET", t });
        dispatch(setAllWorkSchedules(data));

        setResponseData(data);
        setRows(
          data.map((e) => ({
            id: e._id,
            name: e.name,
            description: e.description,
            type: e.type,
            isActive: e.isActive === true ? t("general:Si") : t("general:No"),
          })),
        );
      } catch (e) {
        console.log(e);
      }
    };

    list();
  }, []);

  const actionOnClick = async (row) => {
    try {
      let data = reponseData.find((e) => row.id === e._id);

      const newWeeks = data.weeks.map((week) => {
        const newWeek = Object.keys(week).reduce((acc, curr) => {
          if (week[curr]) {
            acc[curr] = week[curr];
          }

          return acc;
        }, {});
        return newWeek;
      });

      toWorkSchedules({ ...data, weeks: newWeeks });
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const toWorkSchedules = (workSchedule) => {
    const minDate = workSchedule.startOfTheSchedule
      ? workSchedule.startOfTheSchedule
      : "";
    console.log({ ...workSchedule, minDate });
    dispatch(setWorkSchedule({ ...workSchedule, minDate }));
    navigate("/cereza-organization/shift-attendance/work-schedule");
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} md={12}>
        <SelectingTable
          headCells={headCells}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
          name={t("shiftAttendance:WorkSchedules")}
          actionOnClick={actionOnClick}
        >
          <Grid
            container={true}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item={true} xs={12} md={3}>
              <UIButton
                label={t("shiftAttendance:New")}
                onClick={() => {
                  toWorkSchedules({
                    name: "",
                    description: "",
                    isActive: true,
                    type: "",
                    lunch: "",
                    rotations: 0,
                    weeks: [{}],
                    startOfTheSchedule: null,
                  });
                }}
              />
            </Grid>
          </Grid>
        </SelectingTable>
      </Grid>
    </Grid>
  );
};
