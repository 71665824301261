import { useTranslation } from "react-i18next";
import { UIBootstrapDialog } from "components";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TableReports } from "./TableReports";
import { getSeniorityString } from "./ExcelFunctions";
import { getVacationReport } from "api";
import {
  Box,
  Grid,
  Divider,
  DialogContent,
  Tab,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { TrackingModal } from "./TrackingModal";

export const ModalReport = ({ open, setOpen, employee, employeesDic }) => {
  const { t } = useTranslation(["shiftAttendance"]);

  const fromTracking = useRef(false);
  const [openTracking, setOpenTracking] = useState(false);
  const [employeeInfoFields, setEmployeeInfoFields] = useState(employee);
  const [tabSelected, setTabSelected] = useState("APPROVED");
  const [reports, setReports] = useState(null);
  const [rows, setRows] = useState([]);
  const [entrieRow, setEntrieRow] = useState([]);

  const tabData = [
    {
      value: "APPROVED",
      label: t("Authorised"),
      followUpHeader: t("ApprovalDate"),
      finalHeader: t("Balance"),
      dontShow: ["status", "reason"],
    },
    {
      value: "REJECTED",
      label: t("Rejected"),
      followUpHeader: t("RejectionDate"),
      finalHeader: t("MotiveReason"),
      dontShow: ["status", "balance"],
    },
    {
      value: "CANCELED",
      label: t("Cancelled"),
      followUpHeader: t("ApprovalDate"),
      finalHeader: t("Motiv<F3>eReason"),
      dontShow: ["status", "balance", "reason"],
    },
  ];

  const fetchVacationReport = async () => {
    try {
      const { data } = await getVacationReport(employee._id);
      const flattened = Object.values(data).flat();
      const filteredReports = flattened.map((obj) => ({
        folio: obj.folio,
        application_date: obj.application_date,
        approval_date: obj.approval_date,
        startDate: dayjs(obj.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(obj.endDate).format("YYYY-MM-DD"),
        days: obj.days,
        balance: obj.balance,
        status: obj.status,
        signatures: obj.signatures,
        reason: (
          <>
            {obj.signatures.map((cur, i) => (
              <Typography key={i} variant="body2" color="text.secondary">
                {cur.reason}
              </Typography>
            ))}
          </>
        ),
      }));
      setReports(filteredReports);
    } catch (error) {
      console.error("Error fetching vacation report:", error);
      setReports([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchVacationReport();
    }
    if (employee) {
      setEmployeeInfoFields({
        ...employee,
        seniority: getSeniorityString(employee.dateJoiningCompany, t),
      });
    }
  }, [open]);

  useEffect(() => {
    if (reports) {
      setRows(reports.filter((report) => report.status === tabSelected));
    }
  }, [reports, tabSelected]);

  const handleTabChange = (event, newTab) => {
    setTabSelected(newTab);
  };

  const handleClose = () => {
    if (!fromTracking.current) {
      setReports(null);
      setRows([]);
      setTabSelected("APPROVED");
    }
    setOpen(false);
  };

  const fieldsGroups = [
    [
      { label: "empleado", name: "employee" },
      { label: "CTemployeeNumber", name: "number" },
      { label: "CTemail", name: "email" },
    ],
    [
      { label: "CTposition", name: "position" },
      { label: "CTdepartment", name: "department" },
      { label: "Seniority", name: "seniority" },
    ],
    [
      { label: "TotalVacationDays", name: "balance" },
      { label: "GrantedDays", name: "daysGranted" },
      { label: "AvalibleDays", name: "availableDays" },
    ],
  ];

  const handleClickEntrie = (row) => {
    if (row) {
      if (row.status !== "CANCELED") {
        fromTracking.current = true;
        setEntrieRow(row);
        setOpenTracking(true);
        setOpen(false);
      }
    } else {
      Toast.fire({
        icon: "error",
        title: t("recruitment:NoInformationAvailable"),
      });
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <>
      <UIBootstrapDialog
        title={t("VacationReport")}
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
      >
        {reports === null ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ height: "70vh" }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <DialogContent>
            <Grid container spacing={2}>
              {fieldsGroups.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {group.map((field) => (
                    <Grid item xs={12} md={4} key={field.name}>
                      <Typography variant="body2" color="text.secondary">
                        {capitalizeFirstLetter(t(field.label))}
                      </Typography>
                      <Typography variant="body1">
                        {employeeInfoFields[field.name]}
                      </Typography>
                    </Grid>
                  ))}
                  {groupIndex < fieldsGroups.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
            <TabContext value={tabSelected}>
              <Box sx={{ marginTop: 2 }}>
                <TabList onChange={handleTabChange}>
                  {tabData.map((tab) => (
                    <Tab
                      key={tab.value}
                      value={tab.value}
                      label={
                        <Typography
                          sx={{
                            display: { md: "block" },
                          }}
                        >
                          {tab.label}
                        </Typography>
                      }
                      {...a11yProps(tab.value)}
                    />
                  ))}
                </TabList>
                {tabData.map((tab, i) => (
                  <TabPanel
                    sx={{ padding: 0, paddingTop: 2 }}
                    key={tab.value}
                    value={tab.value}
                  >
                    <TableReports
                      handleClick={handleClickEntrie}
                      rows={rows}
                      setRows={setRows}
                      dontShow={[...tab.dontShow, "signatures"]}
                      followUpHeader={tab.followUpHeader}
                      finalHeader={i === 2 ? null : tab.finalHeader}
                    />
                  </TabPanel>
                ))}
              </Box>
            </TabContext>
          </DialogContent>
        )}
      </UIBootstrapDialog>
      <TrackingModal
        open={openTracking}
        setOpen={setOpenTracking}
        setOpenParent={setOpen}
        fromTracking={fromTracking}
        row={entrieRow}
        employeesDic={employeesDic}
      />
    </>
  );
};

const a11yProps = (value) => ({
  id: `tab-${value}`,
  "aria-controls": `tabpanel-${value}`,
});
