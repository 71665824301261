import { useTranslation } from "react-i18next";
import { UIBootstrapDialog } from "components";
import { Box, DialogContent, Avatar, Stack, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function TrackingTimeline({ signatures, employeesDic, status }) {
  const { t } = useTranslation(["request"]["shiftAttendance"]);
  const now = dayjs.tz();

  const getAgo = (now, time) =>
    now.diff(time, "hour") < 24 ? time.from() : time.format("LLLL");

  const getStringDate = (signature) => {
    if (!signature.signed) return "";
    const approvedAt = dayjs.tz(signature.signed);
    return getAgo(now, approvedAt);
  };

  const getSignString = (signature, status) => {
    let textStatus =
      status === "REJECTED" ? t("request:Rejected") : t("request:Approved");
    textStatus += ", ";
    return `${textStatus}${getStringDate(signature)}`;
  };

  const getReasonString = (signature) => {
    const reason = signature?.reason ? signature.reason : "";
    const type =
      signature.type === "NORMAL"
        ? t("shiftAttendance:Manual")
        : t("shiftAttendance:Automatic");
    return `(${type}) ${reason}`;
  };

  const getSignerName = (signature) => signature.employee;

  const getThumbnail = (signature) => employeesDic[signature.employee_id];

  return (
    <Timeline position="alternate">
      {signatures
        .slice()
        .reverse()
        .map((signature, idx) => (
          <TimelineItem key={idx}>
            <TimelineOppositeContent color="text.secondary">
              <Stack>
                <span>{getSignString(signature, status)}</span>
                <span>{getReasonString(signature)}</span>
              </Stack>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pt: 0 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={idx % 2 === 0 ? "flex-start" : "flex-end"}
                gap={2}
              >
                <Avatar src={getThumbnail(signature)} />
                {getSignerName(signature)}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

export const TrackingModal = ({
  open,
  setOpen,
  setOpenParent,
  fromTracking,
  row,
  employeesDic,
}) => {
  const { t } = useTranslation(["shiftAttendance"]);
  const titleStatus = row?.status === "REJECTED" ? t("shiftAttendance:Rejection") : t("shiftAttendance:Approval");
  const title = `${t("sidenav:Seguimiento")} - ${titleStatus}`;
  return (
    <UIBootstrapDialog
      title={title}
      open={open}
      setOpen={setOpen}
      onClose={() => {
        fromTracking.current = false;
        setOpenParent(true);
        setOpen(false);
      }}
    >
      <DialogContent>
        {row?.signatures?.length > 0 && (
          <TrackingTimeline
            signatures={row.signatures.filter((obj) => obj.signed !== null)}
            employeesDic={employeesDic}
            status={row.status}
          />
        )}
      </DialogContent>
    </UIBootstrapDialog>
  );
};
