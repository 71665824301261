import {
  Box,
  Chip,
  Grid,
  Tooltip,
  TableCell,
  TextField,
  Autocomplete,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Toast, UIButton, BasicTable } from "components";
import { Formik, Form } from "formik";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  getVacationReport,
  getVacationSummary,
  selectingEmployeeList,
} from "api";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { ModalReport } from "./ModalReport";
import { exportToExcel } from "./ExcelFunctions";

import HeightIcon from "@mui/icons-material/Height";
import dayjs from "dayjs";
import _ from "lodash";

const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

export const VacationsReport = ({ application = "organization" }) => {
  const { t } = useTranslation();

  const { user } = useSelector(authUserSelect);

  const [alignment, setAlignment] = useState(
    application === "organization" ? "all" : "onlyMy",
  );
  const employeesDic = useRef({});
  const [reports, setReports] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [optionsSelector, setOptionsSelector] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sorting, setSorting] = useState("ASC");
  const [sortType, setSortType] = useState("EMPLOYEE");
  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [employeeReport, setEmployeeReport] = useState(null);
  const [individualReports, setIndividualReports] = useState([]);

  const handleChangeSort = (event) => {
    const direction =
      event !== sortType ? "ASC" : sorting === "ASC" ? "DESC" : "ASC";
    setSorting(direction);
    setSortType(event);
    const employees = value.map((item) => item?._id);
    setFilter(
      createDataFilter({
        data: reports,
        orderBy: event,
        direction,
        employees,
      }),
    );
  };

  useEffect(() => {
    if (filter.length < 1) {
      handleSubmit();
    }
  }, []);

  const handleChange = (event, newAlignment) => {
    if (newAlignment === "onlyMy") {
      setFilter([]);
    } else if (reports) {
      setFilter(
        createDataFilter({
          data: reports,
          orderBy: "EMPLOYEE",
          direction: "ASC",
        }),
      );
    }
    setAlignment(newAlignment);
  };

  const sortData = ({
    filterData,
    orderBy = "EMPLOYEE",
    direction = "ASC",
  }) => {
    if (orderBy === "EMPLOYEE") {
      filterData = filterData.sort((a, b) =>
        direction === "DESC"
          ? a.employee.localeCompare(b.employee)
          : b.employee.localeCompare(a.employee),
      );
    }

    return filterData;
  };

  const createDataFilter = ({
    data,
    employees = [],
    orderBy = "EMPLOYEE",
    direction = "ASC",
  }) => {
    let filterData = _.map(_.flatten(_.values(data)), (obj) => {
      return {
        thumbnail: employeesDic.current[obj.employeeId],
        _id: obj.employeeId,
        employee: obj.employee,
        number: obj.number,
        position: obj.position,
        department: obj.department,
        dateJoiningCompany: obj.dateJoiningCompany,
        balance: obj.balance,
        daysGranted: obj.daysGranted,
        availableDays: obj.availableDays,
        email: obj.email,
      };
    });

    filterData = employees.length
      ? _.filter(filterData, (e) => employees.includes(e._id))
      : filterData;

    filterData = sortData({ filterData, orderBy, direction });

    return filterData;
  };

  const handleSubmit = async () => {
    setOptionsSelector([]);
    setFilter([]);
    setReports([]);
    setValue([]);

    try {
      const { data } =
        alignment === "all"
          ? await getVacationSummary(null)
          : await getVacationSummary(user?._id);

      setIndividualReports([]);

      getVacationReport(alignment === "all" ? null : user?._id)
        .then(({ data: d }) =>
          setIndividualReports(
            d.map((obj) => ({
              ...obj,
              endDate: dayjs(obj.endDate).format("YYYY-MM-DD"),
              startDate: dayjs(obj.startDate).format("YYYY-MM-DD"),
            })),
          ),
        )
        .catch((error) => {
          console.error(error);
        });

      const { data: d1 } = await selectingEmployeeList();
      employeesDic.current = d1.reduce(
        (res, i) => ((res[i._id] = i.thumbnail), res),
        {},
      );
      if (alignment === "all") {
        setOptionsSelector(
          _.orderBy(
            _.uniqBy(
              _.map(_.flatten(_.values(data)), (i) => ({
                _id: i.employeeId,
                name: i.employee,
              })),
              "_id",
            ),
            "name",
          ),
        );
      } else {
        setOptionsSelector([]);
      }

      setReports(data);
      setFilter(
        createDataFilter({
          data: data,
          orderBy: sortType,
          direction: sorting,
        }),
      );
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("recruitment:ErrorAlIntentarLaAccion"),
      });
      console.log("error: ", error);
    } finally {
      setIsReady(true);
    }
  };

  const handleSubmitFilter = async (values) => {
    const employees = values.map((item) => item?._id);
    setFilter(
      createDataFilter({
        data: reports,
        orderBy: sortType,
        direction: sorting,
        employees,
      }),
    );
  };

  const handleClickAssign = (row) => {
    if (row._id) {
      setEmployeeReport(row);
      setOpen(true);
    } else {
      Toast.fire({
        icon: "error",
        title: t("recruitment:NoInformationAvailable"),
      });
    }
  };

  const head = [
    null,
    t("shiftAttendance:Empleado"),
    t("shiftAttendance:CTemployeeNumber"),
    t("shiftAttendance:CTposition"),
    t("shiftAttendance:CTdepartment"),
    t("shiftAttendance:EntranceDate"),
    t("shiftAttendance:TotalVacationDays"),
    t("shiftAttendance:GrantedDays"),
    t("shiftAttendance:AvalibleDays"),
  ];

  const exportTo = () => {
    exportToExcel(filter, individualReports, t);
  };

  return (
    <>
      {!isReady ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ height: "70vh" }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Box>
          <Grid container spacing={2} mb={2}>
            <Grid item={true} xs={12} lg={8}>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {t("sidenav:VacationsReport")}
              </Typography>
            </Grid>
          </Grid>
          <Formik
            initialValues={{
              dateFrom: yesterday,
              dateTo: yesterday,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <Grid container spacing={2} mb={2}>
                  <Grid item={true} xs={12} lg={8}>
                    <Autocomplete
                      options={optionsSelector}
                      getOptionLabel={(option) => option.name}
                      value={value}
                      multiple={true}
                      isOptionEqualToValue={(option, value) =>
                        option?._id === value?._id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("shiftAttendance:FiltroEmpleados")}
                        />
                      )}
                      onChange={(event, newValue) => {
                        handleSubmitFilter(newValue);
                        setValue(newValue);
                      }}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={option._id}
                              label={option.name}
                              {...tagProps}
                            />
                          );
                        })
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={3} lg={2}></Grid>
                  <Grid item={true} xs={12} md={3} lg={2}>
                    <UIButton
                      label={t("general:Exportar")}
                      style={{ height: "56px" }}
                      loading={formik.isSubmitting || !isReady}
                      onClick={exportTo}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <BasicTable
            rows={filter}
            handleClick={handleClickAssign}
            customStyle={"AttendanceReports"}
            PerPage={25}
            color={false}
            dense={true}
            dontShow={["_id", "email"]}
          >
            <TableCell>{head[0]}</TableCell>
            <TableCell>
              <Tooltip title={t("general:Ordenar")}>
                <Box
                  sx={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    handleChangeSort("EMPLOYEE");
                  }}
                >
                  {head[1]}
                  <HeightIcon sx={{ opacity: "0.9" }} />
                </Box>
              </Tooltip>
            </TableCell>
            {head.slice(2).map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </BasicTable>
        </Box>
      )}
      <ModalReport
        open={open}
        setOpen={setOpen}
        employee={employeeReport}
        employeesDic={employeesDic.current}
      />
    </>
  );
};
