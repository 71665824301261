import {
  headerStyleCanceladas,
  headerStyleRechazadas,
  headerStyleAutorizadas,
  headerStyleResumen,
  titleResumenStyle,
  authorizedEntrieStyle,
  rejectedEntrieStyle,
  cancelledEntrieStyle,
  rowEmployeeInfoStyle,
} from "./ExcelStyles";
import {
  generateResumenScheme,
  generateEmployeeScheme,
  indexAuthorizedHeader,
} from "./ExcelSheetsSchemes";
import XLSX from "xlsx-js-style";
import dayjs from "dayjs";

export function applyRowStyle(worksheet, rowNumber, style) {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let col = range.s.c; col <= range.e.c; col++) {
    const cellAddress = XLSX.utils.encode_cell({ r: rowNumber, c: col });
    if (worksheet[cellAddress]) {
      worksheet[cellAddress].s = { ...worksheet[cellAddress].s, ...style };
    }
  }
}

export function getSeniorityString(fechaStr, t) {
  const fechaInicio = new Date(fechaStr);
  const hoy = new Date();

  let years = hoy.getFullYear() - fechaInicio.getFullYear();
  let months = hoy.getMonth() - fechaInicio.getMonth();
  let days = hoy.getDate() - fechaInicio.getDate();

  if (days < 0) {
    const mesAnterior = new Date(hoy.getFullYear(), hoy.getMonth(), 0);
    days += mesAnterior.getDate();
    months--;
  }
  if (months < 0) {
    months += 12;
    years--;
  }
  const parts = [
    years > 0 ? `${t("employee:Years")}: ${years}` : null,
    months > 0 ? `${t("shiftAttendance:Months")}: ${months}` : null,
    days > 0 ? `${t("shiftAttendance:Days")}: ${days}` : null,
  ].filter(Boolean);
  return parts.join(", ");
}

export const adjustCellWide = (ws, data) => {
  let maxColumns = 0;
  data.forEach((row) => {
    if (row.length > maxColumns) maxColumns = row.length;
  });

  const colWidths = [];
  const mergedCells = ws["!merges"] || [];

  for (let colIndex = 0; colIndex < maxColumns; colIndex++) {
    let maxLength = 0;

    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      // Ignorar celdas que forman parte de celdas combinadas.
      const isMerged = mergedCells.some(
        ({ s, e }) =>
          rowIndex >= s.r &&
          rowIndex <= e.r &&
          colIndex >= s.c &&
          colIndex <= e.c,
      );
      if (isMerged) continue;

      const cell = data[rowIndex][colIndex];
      let cellValue = "";
      if (cell) {
        cellValue = cell.v !== undefined ? cell.v : cell;
      }
      const text = cellValue.toString();

      let lengthToConsider = 0;
      if (/\r?\n/.test(text)) {
        const splittedWords = text.split(/\s+/);
        lengthToConsider = splittedWords.reduce(
          (acc, word) => Math.max(acc, word.length),
          0,
        );
      } else {
        lengthToConsider = text.length;
      }
      maxLength = Math.max(maxLength, lengthToConsider);
    }
    colWidths.push({ wch: maxLength + 2 });
  }

  ws["!cols"] = colWidths;
};

export function generateEntriesForSection(worksheetData, newData, startIndex) {
  if (isNaN(startIndex) || !Array.isArray(worksheetData)) return false;
  let indexS = startIndex;

  for (let iBeta = 0; iBeta < newData.length; iBeta++, indexS++) {
    const newEntry = newData[iBeta];
    worksheetData.splice(indexS, 0, newEntry);
  }
  return indexS;
}

function getSafeSheetName(employeeName, employeeNumber) {
  const employeeNameSafe =
    employeeName.length > 12 ? employeeName.slice(0, 12) + "..." : employeeName;
  const sheetNameSafe =
    employeeNumber.length > 13 && employeeNumber.length < 31
      ? employeeNumber
      : `${employeeNameSafe} ${employeeNumber}`;
  return sheetNameSafe;
}

export function addHyperlink(entriesData) {
  for (let index = 0; index < entriesData.length; index++) {
    const employeeName = entriesData[index][0];
    const employeeNumber = entriesData[index][1] + "";
    const sheetNameSafe = getSafeSheetName(employeeName, employeeNumber);
    entriesData[index][0] = {
      t: "s",
      f: `"${employeeName}"`,
      v: employeeName,
      l: {
        Target: `#'${sheetNameSafe}'!A1`,
        Tooltip: employeeName,
      },
      s: {
        font: {
          color: { rgb: "0563C1" },
          underline: true,
        },
      },
    };
  }
}
export function addEmployeePageEntries(
  employeeWorksheetSchema,
  entriesAutorizados,
  entriesRechazados,
  entriesCancelados,
  t,
) {
  const indexHeaderAutorizadas =
    employeeWorksheetSchema.findIndex(
      (subArray) =>
        subArray[1]?.v === t("shiftAttendance:Authorised").toUpperCase(),
    ) + 1;
  const indexHeaderRechazadas =
    generateEntriesForSection(
      employeeWorksheetSchema,
      entriesAutorizados,
      indexHeaderAutorizadas + 1,
    ) + 3;
  const indexHeaderCanceladas =
    generateEntriesForSection(
      employeeWorksheetSchema,
      entriesRechazados,
      indexHeaderRechazadas + 1,
    ) + 3;
  const indexLastEntrie = generateEntriesForSection(
    employeeWorksheetSchema,
    entriesCancelados,
    indexHeaderCanceladas + 1,
  );
  return {
    autorizadas: indexHeaderAutorizadas,
    rechazadas: indexHeaderRechazadas,
    canceladas: indexHeaderCanceladas,
    lastEntrie: indexLastEntrie,
  };
}

export function addEmployeePagesFromResumenEntries(
  workbook,
  entriesResumen,
  rowsResumen,
  employeesReports,
  t,
) {
  const reportsToEmployeeEntries = (lista, motiveFlag) => {
    return lista.map((obj) => [
      null,
      obj.folio,
      obj.application_date,
      obj.approval_date,
      {
        v: obj.signatures.reduce(
          (acc, val, i, arr) =>
            (acc += val.signed
              ? val.employee +
                " " +
                val.signed +
                " " +
                (val.type === "NORMAL"
                  ? `(${t("shiftAttendance:Manual")})`
                  : `(${t("shiftAttendance:Automatic")})`) +
                (val.reason ? ` ${val.reason}` : "") +
                (i < arr.length - 1 ? "\n" : "")
              : ""),
          "",
        ),
        s: {
          alignment: {
            wrapText: true,
          },
        },
      },
      obj.startDate,
      obj.endDate,
      obj.days,
      !motiveFlag ? obj.balance : null,
    ]);
  };

  entriesResumen.forEach((entrie, i) => {
    const clonEmployeeWorksheeSchema = Array.from(
      generateEmployeeScheme(rowsResumen[i], t),
    );
    const employeeReport = employeesReports.filter(
      (obj) => obj.employee === rowsResumen[i]._id,
    );
    const indexes = addEmployeePageEntries(
      clonEmployeeWorksheeSchema,
      reportsToEmployeeEntries(
        employeeReport.filter((obj) => obj.status === "APPROVED"),
      ),
      reportsToEmployeeEntries(
        employeeReport.filter((obj) => obj.status === "REJECTED"),
        true,
      ),
      reportsToEmployeeEntries(
        employeeReport.filter((obj) => obj.status === "CANCELED"),
        true,
      ),
      t,
    );

    // Convertir datos a hoja 2 (empleado)
    const employeeWorksheet = XLSX.utils.aoa_to_sheet(
      clonEmployeeWorksheeSchema,
    );
    // Aplicar ajuste de ancho
    adjustCellWide(employeeWorksheet, clonEmployeeWorksheeSchema);

    // Aplicar estilo a la fila de encabezados
    applyRowStyle(
      employeeWorksheet,
      indexAuthorizedHeader,
      headerStyleAutorizadas,
    );
    applyStyleForSectionEntries(
      employeeWorksheet,
      indexAuthorizedHeader + 1,
      indexes.rechazadas - 1,
      authorizedEntrieStyle,
    );
    applyRowStyle(employeeWorksheet, indexes.rechazadas, headerStyleRechazadas);
    applyStyleForSectionEntries(
      employeeWorksheet,
      indexes.rechazadas + 1,
      indexes.canceladas - 1,
      rejectedEntrieStyle,
    );
    applyRowStyle(employeeWorksheet, indexes.canceladas, headerStyleCanceladas);
    applyStyleForSectionEntries(
      employeeWorksheet,
      indexes.canceladas + 1,
      indexes.lastEntrie,
      cancelledEntrieStyle,
    );
    // Añadir hoja empleado al libro
    combineCellSameRowByRange(
      employeeWorksheet,
      0,
      1,
      clonEmployeeWorksheeSchema[13].length - 1,
    );
    applyRowStyle(employeeWorksheet, 0, titleResumenStyle);
    for (let index = 1; index < indexAuthorizedHeader - 1; index++) {
      applyRowStyle(employeeWorksheet, index, rowEmployeeInfoStyle);
    }

    const employeeNumber = entrie[1];
    const employeeName = entrie[0].v;
    // Limite 31 caracteres para nombrar una hoja
    XLSX.utils.book_append_sheet(
      workbook,
      employeeWorksheet,
      getSafeSheetName(employeeName, employeeNumber),
    );
  });
}

export function exportToExcel(rowsResumen, employeesReports, t) {
  const rowsToResumenEntries = (lista) => {
    const textAlternative = "";
    return lista.map((obj) => [
      obj?.employee || textAlternative,
      obj?.number || textAlternative,
      obj?.position || textAlternative,
      obj?.department || textAlternative,
      {
        v: obj?.dateJoiningCompany || textAlternative,
        t: "d",
        z: "yyyy-mm-dd",
      },
      obj?.balance || textAlternative,
      obj?.daysGranted || textAlternative,
      obj?.availableDays || textAlternative,
    ]);
  };
  /* Este codigo depende de la modificación directa a los contenidos de la arrya entriesResumen */
  const entriesResumen = rowsToResumenEntries(rowsResumen);
  addHyperlink(entriesResumen);

  const resumenScheme = generateResumenScheme(t);

  // Hoja 1 (Resumen)
  const indexHeaderResumen = 4;
  const indexLastEntrieResumen =
    generateEntriesForSection(
      resumenScheme,
      entriesResumen,
      indexHeaderResumen + 1,
    ) + 3;

  const worksheet = XLSX.utils.aoa_to_sheet(resumenScheme);
  // Aplicar ajuste de ancho
  combineCellSameRowByRange(worksheet, 0, 0, resumenScheme.at(-1).length - 1);
  applyRowStyle(worksheet, 0, titleResumenStyle);
  adjustCellWide(worksheet, resumenScheme);
  applyRowStyle(worksheet, indexHeaderResumen, headerStyleResumen);
  applyStyleForSectionEntries(
    worksheet,
    indexHeaderResumen + 1,
    indexLastEntrieResumen,
    authorizedEntrieStyle,
  );

  // Crear libro y agregar hoja
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    t("shiftAttendance:Summary"),
  );

  // Hojas (Empleado)
  addEmployeePagesFromResumenEntries(
    workbook,
    entriesResumen,
    rowsResumen,
    employeesReports,
    t,
  );
  let fileName = `${t("sidenav:VacationsReport")} - ${dayjs().format("LLLL")}.xlsx`;

  // Guardar el archivo
  XLSX.writeFile(workbook, fileName);
}

export function combineCellSameRowByRange(ws, row, initialColumn, finalColumn) {
  ws["!merges"] = [
    { s: { r: row, c: initialColumn }, e: { r: row, c: finalColumn } },
  ];
}

export const applyStyleForSectionEntries = (
  ws,
  startIndex,
  finalIndex,
  style,
) => {
  const limit = finalIndex - startIndex;
  for (let index = 0; index < limit; index++) {
    if (index % 2 !== 0 && index !== 0) {
      applyRowStyle(ws, index + startIndex, style);
    }
  }
};
