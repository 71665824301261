import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { UIBootstrapDialog, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { getFiles } from "api";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentGeolocation } from "@redux/slices/ui";
import Map from "components/Map";
import {
  Face as FaceIcon,
  QrCodeScanner as QrCodeScannerIcon,
} from "@mui/icons-material";

export const TrakingModal = ({ open, setOpen, check, employees }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const dispatch = useDispatch();

  const { tenant } = useSelector(tenantSettingsSelect);
  const employee = employees[check.employee];

  const [photo, setPhoto] = useState("");
  const [_, setAddress] = useState(null);

  const ListItemInformation = ({ primary, secondary = "", icon = null }) => {
    return (
      <ListItem sx={{ p: 0 }} secondaryAction={icon}>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  };

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant._id, check.photo);
        setPhoto(data.content);
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };

    if (check.photo) {
      fetchSource();
    }

    if (check.geoLocation) {
      dispatch(
        setCurrentGeolocation({
          lat: check.geoLocation[1],
          lng: check.geoLocation[0],
        }),
      );
    }
  }, [check]);

  return (
    <UIBootstrapDialog
      title={t("shiftAttendance:DetalleDeSeguimiento")}
      open={open}
      setOpen={setOpen}
      maxWidth={"fit-content"}
      fullWidth={false}
    >
      <DialogContent dividers>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <List sx={{ p: 0, minWidth: 250 }}>
            <ListItemInformation
              primary={employee?.fullName}
              secondary={t("shiftAttendance:Empleado")}
            />
            <ListItemInformation
              primary={employee?.department}
              secondary={t("shiftAttendance:Departamento")}
            />
            <ListItemInformation
              primary={employee?.position}
              secondary={t("shiftAttendance:Position")}
            />
          </List>
          <List sx={{ p: 0, minWidth: 250 }}>
            <ListItemInformation
              primary={t(`shiftAttendance:${check.type}`)}
              secondary={t("shiftAttendance:Tipo")}
            />
            <ListItemInformation
              primary={check.registerAt}
              secondary={t("shiftAttendance:Fecha")}
            />
            <ListItemInformation
              primary={check.schedule.name}
              secondary={t("shiftAttendance:Horario")}
            />
            <ListItemInformation
              primary={check.timeClock.name}
              secondary={t("shiftAttendance:Reloj")}
            />
            {check.method && (
              <ListItemInformation
                primary={t("shiftAttendance:Método")}
                icon={
                  check.method === "FR" ? <FaceIcon /> : <QrCodeScannerIcon />
                }
              />
            )}
          </List>
          {check.photo && (
            <>
              {photo ? (
                <img
                  src={photo}
                  alt="photo employee"
                  style={{ width: "256px" }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <CircularProgress />
                </Stack>
              )}
            </>
          )}
          {check.geoLocation && check.geoLocation.length > 0 && (
            <Box width={400}>
              <Map setAddress={setAddress} onlyMap={true} />
            </Box>
          )}
        </Stack>
        <Stack
          mt={2}
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <UIButton
            label={t("general:Cancel")}
            onClick={() => setOpen(false)}
            fullWidth={false}
          />
        </Stack>
      </DialogContent>
    </UIBootstrapDialog>
  );
};
